import { getGaId } from '@front/shared';
import { MenuButton } from '@front/shared/ds';
import { useEffect, useRef } from 'react';

import { useNavMenu } from '../../../context';

export type TNavMenuItemsProps = {
  menuItems: {
    menuItemTitle: string;
    sectionId: string;
    menuItemPrefix?: string;
  }[];
};

const NavMenuItems: React.FC<TNavMenuItemsProps> = ({ menuItems = [] }) => {
  const { activeSection } = useNavMenu();

  const menuRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    const menu = menuRef.current;
    if (!menu) return;

    const handleWheel = (e: WheelEvent): void => {
      e.preventDefault();
      menu.scrollBy({
        left: e.deltaY * 1.5,
      });
    };

    menu.addEventListener('wheel', handleWheel);

    return () => {
      menu.removeEventListener('wheel', handleWheel);
    };
  }, []);

  return (
    <div className='overflow-hidden xl:pe-4 2xl:px-4'>
      <ul
        ref={menuRef}
        className='scrollbar-hidden flex items-center gap-2 self-center overflow-auto whitespace-nowrap px-4 xl:pe-0 2xl:px-0'
      >
        {menuItems.map(({ menuItemTitle, sectionId, menuItemPrefix }) => {
          return (
            <li key={menuItemTitle}>
              <MenuButton
                isActive={activeSection === sectionId}
                onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                  (e.target as HTMLElement).scrollIntoView({
                    block: 'nearest',
                    inline: 'center',
                  });
                }}
                data-ga-id={getGaId(sectionId, 'nav_menu')}
                href={`#${sectionId}`}
              >
                {menuItemPrefix && (
                  <span className='text-interface-500'>{menuItemPrefix}</span>
                )}
                {menuItemTitle}
              </MenuButton>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default NavMenuItems;
