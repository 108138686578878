import { NavMenu as INavMenu } from '@shared/master-types';
import React from 'react';

import { useActions } from '../../../features/actions/useActions.hook';
import NavMenu from '../NavMenu/NavMenu';

export type TNavMenuConnectedProps = Pick<
  Required<INavMenu>,
  'navMenuItems' | 'logoIcon' | 'action'
> & {
  title?: INavMenu['title'];
};

const NavMenuConnected: React.FC<TNavMenuConnectedProps> = props => {
  const {
    navMenuItems,
    title,
    action: { buttonColor, ...navMenuAction },
    logoIcon,
  } = props;

  const [action] = useActions([navMenuAction]);

  const button = {
    buttonColor,
    action,
  };

  const logo = {
    logoText: title,
    logoIcon: logoIcon,
  };

  return <NavMenu button={button} logo={logo} menuItems={navMenuItems} />;
};

export default NavMenuConnected;
