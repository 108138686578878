import { getGaId } from '@front/shared';
import {
  Button,
  EActionType,
  EButtonSize,
  EButtonTheme,
  TAction,
} from '@front/shared/ds';
import { useFixedActionVisibility } from '@front/shared/utils';
import clsx from 'clsx';
import Link from 'next/link';
import { useCallback, useState } from 'react';

export type TNavMenuButtonProps = {
  action: TAction;
  buttonColor?: string;
  className?: string;
};

const NavMenuButton: React.FC<TNavMenuButtonProps> = ({
  buttonColor,
  className,
  action,
}) => {
  const [hover, setHover] = useState(false);

  const isFixedActionVisible = useFixedActionVisibility();

  const handleMouseEnter = useCallback((): void => {
    setHover(true);
  }, []);

  const handleMouseLeave = useCallback((): void => {
    setHover(false);
  }, []);
  const actionButton =
    action.type === EActionType.ButtonType ||
    (action.type === EActionType.LinkType && (
      <Button
        theme={EButtonTheme.Primary}
        size={EButtonSize.Small}
        className={clsx(
          'h-10 rounded-full px-6 py-[0.719rem] text-sm leading-loose',
          className,
        )}
        onMouseEnter={buttonColor ? handleMouseEnter : undefined}
        onMouseLeave={buttonColor ? handleMouseLeave : undefined}
        style={
          buttonColor
            ? {
                backgroundColor: buttonColor,
                opacity: hover ? 0.8 : 1,
              }
            : {}
        }
        {...action.props}
        data-ga-id={getGaId(action.dataGaId || action.text, 'nav-menu')}
      />
    ));

  return (
    <>
      <div className='hidden xl:flex'>{actionButton}</div>
      <div
        className={clsx(
          'flex transition-opacity xl:hidden',
          !isFixedActionVisible
            ? 'pointer-events-none -z-10 opacity-0'
            : 'pointer-events-auto z-10 opacity-100',
        )}
      >
        {actionButton}
      </div>
    </>
  );
};
export default NavMenuButton;
