import { HorizontalLogo, THorizontalLogoProps } from '@front/shared/ds';
import { getGaId } from '@front/shared/utils';

export type TNavMenuLogoProps = THorizontalLogoProps & { className?: string };

const NavMenuLogo: React.FC<TNavMenuLogoProps> = ({
  logoText,
  logoIcon,
  className,
}) => {
  return (
    <a
      href='#_app'
      data-ga-id={getGaId(logoText, 'header_logo')}
      className={className}
    >
      <HorizontalLogo logoIcon={logoIcon} logoText={logoText} />
    </a>
  );
};

export default NavMenuLogo;
