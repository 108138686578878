export enum EScrollIndicatorTheme {
  Brand = 'brand',
  LightBrand = 'lightbrand',
}

export enum EScrollIndicatorSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export enum EScrollIndicatorBackground {
  Transparent = 'transparent',
  Grey = 'grey',
}
