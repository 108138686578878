// @see https://github.com/SMAKSS/react-scroll-direction/blob/master/src/useDetectScroll.ts

import { useState, useEffect, useCallback, useRef } from 'react';

import {
  EAxis,
  EDirection,
  TScrollInfo,
  TScrollPosition,
  TScrollProps,
} from './types';

export { EDirection as EDetectScrollDirection };

export function useDetectScroll(props: TScrollProps = {}): TScrollInfo {
  const {
    target = typeof window !== 'undefined' ? window : undefined,
    thr = 0,
    axis = EAxis.Y,
    scrollUp = axis === EAxis.Y ? EDirection.Up : EDirection.Left,
    scrollDown = axis === EAxis.Y ? EDirection.Down : EDirection.Right,
    still = EDirection.Still,
  } = props;

  const [scrollDir, setScrollDir] = useState<EDirection>(still);
  const [scrollPosition, setScrollPosition] = useState<TScrollPosition>({
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  });
  const [isTimeoutPassed, setIsTimeoutPassed] = useState(false);

  const threshold = Math.max(0, thr);
  const ticking = useRef(false);
  const lastScroll = useRef(0);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  /** Function to update scroll direction. */
  const updateScrollDir = useCallback(() => {
    if (!target) return;

    let scroll: number;
    if (target instanceof Window) {
      scroll = axis === EAxis.Y ? target.scrollY : target.scrollX;
    } else {
      scroll = axis === EAxis.Y ? target.scrollTop : target.scrollLeft;
    }

    if (Math.abs(scroll - lastScroll.current) >= threshold) {
      if (isTimeoutPassed) {
        setScrollDir(scroll > lastScroll.current ? scrollDown : scrollUp);
      }
      lastScroll.current = Math.max(0, scroll);
    }
    ticking.current = false;
  }, [target, axis, threshold, scrollDown, scrollUp, isTimeoutPassed]);

  useEffect(() => {
    if (!target) {
      console.warn(
        'useDetectScroll: target is not set. Falling back to window.',
      );
      return;
    }

    /** Function to update scroll position. */
    const updateScrollPosition = (): void => {
      if (!target) return;

      const top = target instanceof Window ? target.scrollY : target.scrollTop;
      const left =
        target instanceof Window ? target.scrollX : target.scrollLeft;

      const bottom =
        (target instanceof Window
          ? document.documentElement.scrollHeight - target.innerHeight
          : target.scrollHeight - target.clientHeight) - top;
      const right =
        (target instanceof Window
          ? document.documentElement.scrollWidth - target.innerWidth
          : target.scrollWidth - target.clientWidth) - left;

      setScrollPosition({ top, bottom, left, right });
    };

    updateScrollPosition();

    const targetElement = target as EventTarget;
    targetElement.addEventListener('scroll', updateScrollPosition);

    return () => {
      targetElement.removeEventListener('scroll', updateScrollPosition);
    };
  }, [target]);

  useEffect(() => {
    if (!target) {
      console.warn(
        'useDetectScroll: target is not set. Falling back to window.',
      );
      return;
    }

    if (target instanceof Window) {
      lastScroll.current = axis === EAxis.Y ? target.scrollY : target.scrollX;
    } else {
      lastScroll.current =
        axis === EAxis.Y ? target.scrollTop : target.scrollLeft;
    }

    const onScroll = (): void => {
      if (!ticking.current) {
        window.requestAnimationFrame(updateScrollDir);
        ticking.current = true;
      }
    };

    const targetElement = target as EventTarget;
    targetElement.addEventListener('scroll', onScroll);

    return () => targetElement.removeEventListener('scroll', onScroll);
  }, [target, axis, updateScrollDir]);

  useEffect(() => {
    timerRef.current = setTimeout(() => {
      setIsTimeoutPassed(true);
    }, 3500);

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  return {
    scrollDir: isTimeoutPassed ? scrollDir : still,
    scrollPosition,
  };
}
