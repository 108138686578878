import clsx from 'clsx';

import NavMenuButton, { TNavMenuButtonProps } from './NavMenuButton';
import NavMenuContainer from './NavMenuContainer';
import NavMenuItems, { TNavMenuItemsProps } from './NavMenuItems';
import NavMenuLogo, { TNavMenuLogoProps } from './NavMenuLogo';

export type TNavMenuProps = {
  menuItems: TNavMenuItemsProps['menuItems'];
  button: TNavMenuButtonProps;
  logo: TNavMenuLogoProps;
};

const NavMenu: React.FC<TNavMenuProps> = ({ button, logo, menuItems }) => {
  const buttonClass =
    'w-full min-w-[121px] max-w-fit whitespace-nowrap bg-brand-500 leading-normal hover:bg-brand-500/80';
  return (
    <>
      <NavMenuContainer>
        <NavMenuLogo
          {...logo}
          className='hidden min-w-fit 2xl:block 2xl:ps-1'
        />
        <NavMenuItems menuItems={menuItems} />
        <NavMenuButton
          {...button}
          className={clsx('hidden xl:static xl:m-0 xl:flex', buttonClass)}
        />
      </NavMenuContainer>
      <NavMenuButton
        {...button}
        className={clsx(
          'fixed inset-x-4 bottom-4 top-auto mx-auto lg:bottom-5 xl:hidden',
          buttonClass,
        )}
      />
    </>
  );
};

export default NavMenu;
