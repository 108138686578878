import {
  useDetectScroll,
  EDetectScrollDirection,
  ScrollIndicator,
  EScrollIndicatorBackground,
  EScrollIndicatorSize,
  EScrollIndicatorTheme,
} from '@front/shared/ds';
import clsx from 'clsx';

export type TNavMenuContainerProps = {
  children: React.ReactNode | React.ReactNode[];
};

const NavMenuContainer: React.FC<TNavMenuContainerProps> = ({ children }) => {
  const scrollInfo = useDetectScroll();
  const backgroundDefaultClasses =
    ' inset-x-0 w-full z-50 max-w-[100vw] bg-white 2xl:group-hover:bg-header-background pointer-events-auto transition-transform duration-300';

  const isNavMenuInTop =
    scrollInfo.scrollDir === EDetectScrollDirection.Up ||
    scrollInfo.scrollDir === EDetectScrollDirection.Still ||
    scrollInfo.scrollPosition.top < 80;

  const topPositionClass = isNavMenuInTop
    ? 'translate-y-0'
    : '-translate-y-[3.5rem] xl:-translate-y-20';

  return (
    <section className={clsx(backgroundDefaultClasses, topPositionClass)}>
      <div className='xl:pe-4 2xl:mx-auto 2xl:max-w-[1232px] 2xl:pe-0'>
        <nav className='relative mx-auto flex h-14 max-w-fit items-center transition lg:py-5 xl:h-20 xl:max-w-full xl:justify-between'>
          {children}
        </nav>
      </div>
      <ScrollIndicator
        showOnlyInProgress
        rounded={false}
        background={EScrollIndicatorBackground.Grey}
        theme={EScrollIndicatorTheme.LightBrand}
        size={EScrollIndicatorSize.Small}
      />
    </section>
  );
};

export default NavMenuContainer;
