import clsx from 'clsx';
import React, { DetailedHTMLProps } from 'react';

type TMenuButtonCommonProps = {
  isActive?: boolean;
};
export type TMenuButtonProps = DetailedHTMLProps<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
> &
  TMenuButtonCommonProps;

const MenuButton: React.FC<TMenuButtonProps> = ({
  children,
  isActive,
  href,
  ...rest
}) => {
  return (
    <a
      className={clsx(
        'box-border h-10 min-w-4 whitespace-nowrap rounded-full px-4 py-2.5 outline-none',
        'flex items-center justify-center gap-2',
        'text-center text-base font-medium leading-loose text-control-950',
        'transition hover:bg-[#0000000A]',
        isActive && 'bg-[#0000000A]',
      )}
      href={href}
      {...rest}
    >
      {children}
    </a>
  );
};

export default MenuButton;
